.widget {
  border: none;
  box-shadow: none;
}

/*---------Palette Box--------*/
.color-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e6ed;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  background: white;
}

.color-box .cl-example {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 6px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: -10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.color-box:hover .cl-example {
  -webkit-transform: scale(1.09);
  transform: scale(1.09);
}

.color-box .cl-info {
  flex: 1;
}

.color-box .cl-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: #3b3f5c;
}

.color-box .cl-info span {
  font-size: 14px;
  color: #888ea8;
  font-weight: 600;
}

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.uploader label {
  float: left;
  clear: both;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.uploader label:hover {
  border-color: #454cad;
}

.uploader label.hover {
  border: 3px solid #454cad;
  box-shadow: inset 0 0 0 6px #eee;
}

.uploader label.hover #start i.fa {
  transform: scale(0.8);
  opacity: 0.3;
}

.uploader #start {
  float: left;
  clear: both;
  width: 100%;
}

.uploader #start.hidden {
  display: none;
}

.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}

.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}

.uploader #response.hidden {
  display: none;
}

.uploader #response #messages {
  margin-bottom: 0.5rem;
}

.uploader #file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

.uploader #file-image.hidden {
  display: none;
}

.uploader #notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}

.uploader #notimage.hidden {
  display: none;
}

.uploader progress,
.uploader .progress {
  display: inline;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  height: 8px;
  border: 0;
  border-radius: 4px;
  background-color: #eee;
  overflow: hidden;
}

.uploader .progress[value]::-webkit-progress-bar {
  border-radius: 4px;
  background-color: #eee;
}

.uploader .progress[value]::-webkit-progress-value {
  background: linear-gradient(to right, #393f90 0%, #454cad 50%);
  border-radius: 4px;
}

.uploader .progress[value]::-moz-progress-bar {
  background: linear-gradient(to right, #393f90 0%, #454cad 50%);
  border-radius: 4px;
}

.uploader input[type="file"] {
  display: none;
}

.uploader div {
  margin: 0 0 0.5rem 0;
  color: #5f6982;
}

.uploader .btn {
  display: inline-block;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  clear: both;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background: #454cad;
  border-color: #454cad;
  cursor: pointer;
}

.page-container {
  margin-top: 112px;
}

.checklist-entete {
  justify-content: space-between;
  align-items: center;
  display: flex;
  border: none;
  background: #ebf2ff;
}

/* CSS for hiding submenu elements initially */
.submenu {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.menu-item {
  display: flex;
  color: #009688;
  font-size: 14px;
}

/* CSS for showing submenu elements */
.submenu.show {
  opacity: 1;
  max-height: 500px;
  /* Adjust this value according to your submenu height */
}

.my-custom-tooltip {
  background-color: #ffffff;
  /* Change this to your desired background color */
  color: #333;
  /* Text color */
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 4px;
  /* Border radius */
  padding: 8px;
  /* Padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Shadow */
}

.notification-bar {
  position: fixed;
  top: 0;
  right: -400px;
  /* Initially hide the notification bar outside the viewport */
  width: 400px;
  /* Adjust width as needed */
  height: 100%;
  background-color: #fff;
  /* Background color of the notification bar */
  z-index: 1000;
  /* Ensure it's above other content */
  transition: right 0.3s ease;
  /* Add transition for smooth animation */
  box-shadow: 0 2px 4px rgba(39, 40, 41, 0.15), 0 8px 16px rgba(46, 46, 47, 0.2);
}

.notification-bar.show {
  right: 0;
  /* Slide in the notification bar from the right */
}

/* ticket */

.anim04c {
  -webkit-transition: all 0.4s cubic-bezier(0.5, 0.35, 0.15, 1.4);
  transition: all 0.4s cubic-bezier(0.5, 0.35, 0.15, 1.4);
}

/*-----*/

.outer {
  position: relative;
  /* top: 50px; */
  z-index: 1;
  /* -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); */
  cursor: pointer;
}

/*-----*/

.signboard {
  width: 500px;
  height: 500px;
  margin: auto;
  color: #fff;
  border-radius: 10px;
}

/*-----*/

.front {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: #ff726b;
}

.right {
  position: absolute;
  right: 0;
  z-index: 2;
  -webkit-transform: rotate(-10deg) translate(7px, 8px);
  -moz-transform: rotate(-10deg) translate(7px, 8px);
  -ms-transform: rotate(-10deg) translate(7px, 8px);
  -o-transform: rotate(-10deg) translate(7px, 8px);
  transform: rotate(-10deg) translate(7px, 8px);
  background: #efc94c;
}

.left {
  position: absolute;
  left: 0;
  z-index: 1;
  -webkit-transform: rotate(5deg) translate(-4px, 4px);
  -moz-transform: rotate(5deg) translate(-4px, 4px);
  -ms-transform: rotate(5deg) translate(-4px, 4px);
  -o-transform: rotate(5deg) translate(-4px, 4px);
  transform: rotate(5deg) translate(-4px, 4px);
  background: #3498db;
}

/*-----*/

/* .outer:hover .inner {
  -webkit-transform: rotate(0) translate(0);
  -moz-transform: rotate(0) translate(0);
  -ms-transform: rotate(0) translate(0);
  -o-transform: rotate(0) translate(0);
  transform: rotate(0) translate(0);
} */
/*-----*/

/* .outer:active .inner {
  -webkit-transform: rotate(0) translate(0) scale(0.9);
  -moz-transform: rotate(0) translate(0) scale(0.9);
  -ms-transform: rotate(0) translate(0) scale(0.9);
  -o-transform: rotate(0) translate(0) scale(0.9);
  transform: rotate(0) translate(0) scale(0.9);
} */
.outer:active .front .date {
  -webkit-transform: scale(2);
}

.outer:active .front .day,
.outer:active .front .month {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.outer:active .right {
  -webkit-transform: rotate(-5deg) translateX(80px) scale(0.9);
  -moz-transform: rotate(-5deg) translateX(80px) scale(0.9);
  -ms-transform: rotate(-5deg) translateX(80px) scale(0.9);
  -o-transform: rotate(-5deg) translateX(80px) scale(0.9);
  transform: rotate(-5deg) translateX(80px) scale(0.9);
}

.outer:active .left {
  -webkit-transform: rotate(5deg) translateX(-80px) scale(0.9);
  -moz-transform: rotate(5deg) translateX(-80px) scale(0.9);
  -ms-transform: rotate(5deg) translateX(-80px) scale(0.9);
  -o-transform: rotate(5deg) translateX(-80px) scale(0.9);
  transform: rotate(5deg) translateX(-80px) scale(0.9);
}

/*-----*/

.outer:active .calendarMain {
  -webkit-transform: scale(1.8);
  opacity: 0;
  visibility: hidden;
}

.outer:active .clock {
  -webkit-transform: scale(1.4);
  opacity: 1;
  visibility: visible;
}

.outer:active .calendarNormal {
  bottom: -30px;
  opacity: 1;
  visibility: visible;
}

.outer:active .year {
  top: -30px;
  opacity: 1;
  visibility: visible;
  letter-spacing: 3px;
}

.hover,
.click,
.yeaa {
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  bottom: 0;
  opacity: 1;
}

.modal-transparent .modal-content {
  background-color: transparent;
  border: none;
}

.modal-transparent .modal-backdrop {
  background-color: transparent;
}

:root {
  --bg: #ebf0f7;
  --header: #fbf4f6;
  --text: #2e2e2f;
  --white: #ffffff;
  --light-grey: #c4cad3;
  --tag-1: #ceecfd;
  --tag-1-text: #2e87ba;
  --tag-2: #d6ede2;
  --tag-2-text: #13854e;
  --tag-3: #ceecfd;
  --tag-3-text: #2d86ba;
  --tag-4: #f2dcf5;
  --tag-4-text: #a734ba;
  --purple: #7784ee;
}

.app {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
}

.app h1 {
  font-size: 30px;
  color: #2e2e2f;
}

.project-tasks {
  display: grid;
  width: 100%;
  grid-column-gap: 1.5rem;
  height: 100%;
}

.project-column-heading {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-column-heading__title {
  font-size: 20px;
  font-family: "Poppins", sans-serif !important;
  color: var(--text);
  font-weight: bold;
}

.task {
  background-color: var(--white);
  padding: 2.5rem;
  border-radius: 8px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  border: 3px dashed transparent;
  height: 100%;
  align-items: center;
  display: grid;
}

.task p {
  font-size: 15px;
  margin: 3rem 0;
}

.task__tag {
  border-radius: 100px;
  padding: 2px 13px;
  font-size: 12px;
  margin-bottom: 20px;
}

.task__tag--copyright {
  color: var(--tag-4-text);
  background-color: var(--tag-4);
}

.task__tag--design {
  color: var(--tag-3-text);
  background-color: var(--tag-3);
}

.task__tag--illustration {
  color: var(--tag-2-text);
  background-color: var(--tag-2);
}

.task__tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task__options {
  background: transparent;
  border: 0;
  color: var(--light-grey);
  font-size: 17px;
}

.task__stats {
  position: relative;
  width: 100%;
  color: var(--light-grey);
  font-size: 12px;
}

.task__stats span:not(:last-of-type) {
  margin-right: 1rem;
}

.task__stats svg {
  margin-right: 5px;
}

.task__owner {
  padding: 10px;
  border-radius: 100rem;
  background: var(--purple);
  color: #fff;
  position: absolute;
  display: inline-block;
  right: 0;
  bottom: 0;
}

.affectation {
  font-weight: bold;
  color: #818181;
  text-decoration: underline;
}

.norad {
  display: none;
}

.soft-icons {
  width: 100px;
  height: 100px;
  margin: 6px 6px;
  border-radius: 10px;
  background-color: rgb(85, 85, 85);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
}

.soft-icons p {
  color: #fff;
  margin: 0;
}

.machine-etiquette {
  text-align: center;
  background-color: #fff;
  color: #555555;
}

.softdesc {
  text-align: center;
}

.softfeatstitle {
  color: #fff;
}

.featspan {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 3px 10px;
  border-radius: 5px;
}

.softfeatdesc {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 50px 10px;
  margin: 15px;
}

.softfeatdesc p {
  color: #fff;
  width: 75%;
  padding: 10px;
  margin: 0 auto;
}

.date-input {
  height: calc(1.4em + 1.4rem + 2px);
  border: none;
  padding: 11px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
}

.fc-toolbar-title {
  text-transform: uppercase;
}

/*
    calendar Modal 
*/
#addEventsModal {
  overflow-x: hidden;
  overflow-y: auto;
}

#addEventsModal .modal-content {
  border: none;
}

#addEventsModal .modal-body {
  padding: 25px 38px;
}

#addEventsModal .modal-body .close {
  cursor: pointer;
}

#addEventsModal .modal-title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #3b3f5c;
}

#addEventsModal form {
  margin-top: 30px;
}

#addEventsModal form label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #e3e4eb;
}

#addEventsModal form .event-badge {
  margin-top: 16px;
}

#addEventsModal form .event-badge p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #e3e4eb;
}

#addEventsModal form input {
  margin-bottom: 25px;
}

#addEventsModal form .form-group {
  margin-bottom: 0;
}

#calendar .form-control {
  margin-bottom: 20px;
  color: #3b3f5c;
  letter-spacing: 2px;
  font-weight: 600;
}

input[readonly] {
  color: #3b3f5c;
}

.modal-footer {
  border-top: none;
  padding-top: 0;
  padding-bottom: 25px;
}

#addEventsModal .modal-footer .btn {
  box-shadow: none;
}

#addEventsModal .modal-footer #add-e {
  background-color: #2196f3;
  color: #fff;
  font-weight: 600;
  border: 1px solid #2196f3;
  padding: 10px 25px;
}

#addEventsModal .modal-footer #edit-event {
  background-color: #009688;
  color: #fff;
  font-weight: 600;
  border: 1px solid #e0e6ed;
  padding: 10px 25px;
}

#addEventsModal .modal-footer [data-dismiss="modal"] {
  background-color: #fff;
  color: #4361ee;
  font-weight: 700;
  border: 1px solid #e8e8e8;
  padding: 10px 25px;
}

td.fc-today.fc-state-highlight span {
  background: #4361ee;
  border-radius: 50%;
  color: #fff;
  display: inline-block !important;
  font-size: 12px;
  font-weight: 700;
}

.fc-day-grid-event .fc-content:before {
  content: "";
  height: 5px;
  background: #000;
  width: 5px;
  position: absolute;
  left: -9px;
  top: 4px;
  border-radius: 50%;
}

.fc-day-grid-event.bg-primary .fc-content:before {
  background: #4361ee;
}

.fc-day-grid-event.bg-success .fc-content:before {
  background: #1abc9c;
}

.fc-day-grid-event.bg-warning .fc-content:before {
  background: #e2a03f;
}

.fc-day-grid-event.bg-danger .fc-content:before {
  background: #e7515a;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media (min-width: 1200px) and (max-width: 1239px) {
  .fc-scroller {
    height: 100% !important;
    position: relative;
    height: calc(100vh - 354px) !important;
  }
}

@media (max-width: 767px) {
  #calendar {
    height: auto !important;
  }

  #calendar .fc-event {
    margin: 0;
  }

  .fc-scroller,
  .fc-agendaWeek-view .fc-scroller.fc-time-grid-container,
  .fc-agendaDay-view .fc-scroller.fc-time-grid-container,
  .fc-view {
    height: auto !important;
  }

  .fc-basic-view .fc-body .fc-row {
    height: 120px !important;
  }

  .fc-toolbar .fc-left {
    float: none;
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }

  .fc .fc-toolbar>*>* {
    float: none;
    vertical-align: bottom;
  }

  .fc-toolbar .fc-right {
    float: none;
    margin: 0 0 30px 0;
  }

  .fc-toolbar .fc-center {
    margin: 0 0 30px 0;
  }
}

.dot-line {
  border: none;
  border-bottom: 1px dotted #000;
  /* Color of the dots */
  width: 100%;
  /* Full width of the line */
  margin: 25px 0px 14px 0px;
  /* Adjust margin as needed */
  opacity: 60%;
}

.file-input-wrapper {
  position: fixed;
  overflow: hidden;
  display: inline-block;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 1;
}

.file-input-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.file-input-wrapper label {
  display: inline-block;
  padding: 8px 20px;
  background-color: #4caf50;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 4px 2px rgba(31, 45, 61, 0.1);
}

.file-input-wrapper label:hover {
  background-color: #45a049;
}

.login-page {
  background-image: url(../public/assets/assets/img/login.png);
  background-position: center;
  background-size: cover;
}

.terms-conditions {
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  color: #fff;
}

.action-btn {
  padding: 3px 8px !important;
}

/* General layout and responsiveness */
.admin-data-content {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.date-filter-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.widget {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.widget-title {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.widget-content {
  overflow-x: auto;
  max-width: 100%;
}

/* Responsiveness for small screens */
@media (max-width: 768px) {
  .date-filter-row {
    flex-direction: column;
    gap: 10px;
  }

  .layout-spacing {
    margin-bottom: 20px;
  }

  .widget {
    padding: 15px;
  }
}

/* Table responsiveness */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 10px;
  text-align: center;
}

table thead {
  background-color: #f7f7f7;
}

table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

table {
  overflow-x: auto;
}

.table-wrapper {
  overflow-x: auto;
}








.gantt-wrapper {
  width: 100%;
  padding: 20px;
  overflow-x: auto;
}

.gantt-header {
  color: #202125;
  margin-bottom: 40px;
  text-align: center;
}

.gantt-header h2 {
  font-weight: 600;
}

.gantt-gantt {
  display: grid;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 75px 125px -57px #7e8f94;
}

/* Time Header Row */
.gantt-gantt__row--months {
  color: #fff;
  background-color: #0a3444;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 150px repeat(24, 1fr); /* Adjust to match 24-hour grid */
}

.gantt-gantt__row--months span {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  padding: 8px 0;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

/* Gantt Rows */
.gantt-gantt__row {
  display: grid;
  grid-template-columns: 150px repeat(24, 1fr); /* Adjust to match 24-hour grid */
  align-items: center;
}

.gantt-gantt__row:nth-child(odd) .gantt-gantt__row-first {
  background-color: #f5f5f5;
}

/* User Column */
.gantt-gantt__row-first {
  font-size: 13px;
  font-weight: bold;
  padding: 15px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/* Task Bars */
.gantt-gantt__row-bars {
  display: grid;
  grid-template-columns: repeat(24, 1fr); /* Create 24 columns for hours */
  padding: 5px 0;
  list-style: none;
  margin: 0;
}

.gantt-gantt__row-bars li {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  overflow: hidden;
  padding: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1; /* Ensure bars are on top */
}

/* Machine-Specific Colors */
.gantt-gantt__row-bars li.machine-M_116 {
  background-color: #FF6347; /* Tomato */
}

.gantt-gantt__row-bars li.machine-M_013 {
  background-color: #4682B4; /* Steel Blue */
}

.gantt-gantt__row-bars li.machine-M_115 {
  background-color: #32CD32; /* Lime Green */
}

.gantt-gantt__row-bars li.machine-M_123 {
  background-color: #FFD700; /* Gold */
}

.gantt-gantt__row-bars li.machine-M_131 {
  background-color: #8A2BE2; /* Blue Violet */
}

.gantt-gantt__row-bars li.machine-DIVERS {
  background-color: #D3D3D3; /* Light Gray */
}

/* Hover Effects */
.gantt-gantt__row-bars li:hover {
  opacity: 0.85;
  transition: opacity 0.3s ease-in-out;
}

.timeline {
  position: relative;
  height: 40px;
  background: #f5f5f5;
  border: 1px solid #ddd;
}

.timeline-bar {
  position: absolute;
  height: 100%;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 40px;
}